import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BiSave } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import Loader from '../../Loader';
import { updateDateDeliveryRound } from '../../../actions/deliveryRounds';
import styles from './delivery-round-day-form.module.scss';

import {
  Textarea,
  InputNumber,
  InputFormatPattern,
  ErrorField,
  InputText,
} from '../../../lib/HooksFormFields';
import { formatedDateForObjectDate } from '../../../utils';

const DeliveryRoundDayForm = ({ day, handleSuccess, isLoading }, ref) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors, isDirty },
    trigger,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      target: 0,
    },
  });

  let disabledbutton = false;
  // Fonction pour normaliser la date à minuit
  function normalizeDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  const dateDuJour = normalizeDate(new Date());
  const dateJourSelectionne = normalizeDate(formatedDateForObjectDate(day.date));

  // Comparaison des dates (sans les heures, minutes, etc.)
  if (dateJourSelectionne.getTime() !== dateDuJour.getTime()) {
    if (day.team === 'feeder') {
      const dateDuJourMoinsUn = normalizeDate(new Date());
      dateDuJourMoinsUn.setDate(dateDuJourMoinsUn.getDate() - 1);

      if (dateJourSelectionne.getTime() < dateDuJourMoinsUn.getTime()) {
        disabledbutton = true;
      } else if (dateJourSelectionne.getTime() > dateDuJourMoinsUn.getTime()) {
        disabledbutton = true;
      } else if (dateJourSelectionne.getTime() === dateDuJour.getTime()) {
        disabledbutton = true;
      } else {
        disabledbutton = false;
      }
    } else {
      disabledbutton = true;
    }
  }

  if (day.team === 'feeder' && dateJourSelectionne.getTime() === dateDuJour.getTime()) {
    disabledbutton = true;
  }

  async function onSubmit(data) {
    console.log(data);
    const updatedTags = day.tags?.map((tag) => ({
      ...tag,
      numberOfDeliveriesTag: data[tag._id] || 0,
    }));
    const res = await updateDateDeliveryRound(dispatch, {
      _id: day._id,
      ...data,
      tags: updatedTags || [],
    });
    if (res.status === 200) {
      if (handleSuccess) handleSuccess();
      const resetValues = {
        start: res.data.day.start || '',
        end: res.data.day.end || '',
        breakHours: res.data.day.breakHours || '',
        comments: res.data.day.comments || '',
        target: res.data.day.target || 0,
        kms: res.data.day.kms || 0,
        licensePlate: res.data.day.licensePlate || '',
      };
      if (day.team === 'feeder') {
        resetValues.licensePlateTwo = res.data.day.licensePlate2 || '';
        resetValues.kmsTwo = res.data.day.kms2 || 0;
      }
      res.data.day.tags.forEach((tag) => {
        resetValues[tag._id] = tag.numberOfDeliveriesTag || 0;
      });
      reset(resetValues);
    }
    return res.status === 200;
  }

  useImperativeHandle(ref, () => ({
    submit: async () => {
      let isValid = true;
      if (isDirty) {
        isValid = await trigger();
      }
      handleSubmit(onSubmit)();
      return isValid;
    },
  }), [isDirty]);

  // INIT FORM VALUES
  useEffect(() => {
    if (!day) return;

    const defaultValues = {
      start: day.start || '',
      end: day.end || '',
      breakHours: day.breakHours || '',
      comments: day.comments || '',
      target: day.target || 0,
      kms: day.kms || 0,
      licensePlate: day.licensePlate || '',
    };

    if (day.team === 'feeder') {
      defaultValues.licensePlateTwo = day.licensePlateTwo || '';
      defaultValues.kmsTwo = day.kmsTwo || 0;
    }

    if (day.tags) {
      day.tags.forEach((tag) => {
        defaultValues[tag._id] = tag.numberOfDeliveriesTag || 0;
      });
    }
    reset(defaultValues);
  }, [day]);

  return (
    <div className={styles.form}>
      <div className={styles.fields}>

        {/*! Partie feeder */}
        <h3>Véhicule{day.team === 'feeder' ? 's' : ''}</h3>
        {day.team === 'feeder' && <h4>Véhicule 1</h4>}
        <div className={`${styles['container-field']} ${styles.licensePlate}`}>
          <InputText
            name="licensePlate"
            label="Immat vehicule"
            inline
            control={control}
            placeholder="##-###-##"
            maxlength={9}
            mask="**-***-**"
            rules={{
              required: 'Veuillez renseigner l\'immatriculation du véhicule.',
              pattern: {
                value: /(.{2,2})-(.{3,3})-(.{2,2})/,
                message:
                    'Veuillez renseigner une immatriculation au format ##-###-##',
              },
            }}
            className={disabledbutton ? 'disabled secondary' : 'secondary'}
            disabled={disabledbutton}
          />
        </div>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputNumber
            name="kms"
            control={control}
            label="Kms jour"
            placeholder="0"
            inline
            maxlength={6}
            widthLabel="150px"
            className={disabledbutton ? 'disabled secondary' : 'secondary'}
            rules={{
              pattern: {
                message: 'Veuillez renseigner le nombre de kms',
              },
              validate: (value) => value <= 1500 || 'La valeur ne peut pas dépasser 1500km',
            }}
            disabled={disabledbutton}
          />
        </div>
        {day.team === 'feeder' && (
        <>
          <h4>Véhicule 2</h4>
          <div className={`${styles['container-field']} ${styles.licensePlate}`}>
            <InputText
              name="licensePlateTwo"
              label="Immat vehicule 2"
              inline
              control={control}
              placeholder="##-###-##"
              maxlength={9}
              mask="**-***-**"
              rules={{
                pattern: {
                  value: /(.{2,2})-(.{3,3})-(.{2,2})/,
                  message:
                    'Veuillez renseigner une immatriculation au format ##-###-##',
                },
              }}
              className={disabledbutton ? 'disabled secondary' : 'secondary'}
              disabled={disabledbutton}
            />
          </div>
          <div className={`${styles['container-field']} ${styles.time}`}>
            <InputNumber
              name="kmsTwo"
              control={control}
              label="Kms véhic 2"
              placeholder="0"
              inline
              maxlength={6}
              widthLabel="150px"
              className={disabledbutton ? 'disabled secondary' : 'secondary'}
              disabled={disabledbutton}
            />
          </div>
        </>
        )}

        {/*! Fin partie feeder */}

        <h3>Heures</h3>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="start"
            label="Heure de début"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                message: 'Veuillez renseigner l\'heure de début au format ##:## ou une heure valide ',
              },
              validate: {
                isValidTime: (value) => {
                  const [hours, minutes] = value.split(':').map(Number);
                  if (hours < 0 || hours > 23) {
                    return 'Les heures doivent être entre 00 et 23';
                  }
                  if (minutes < 0 || minutes > 59) {
                    return 'Les minutes doivent être entre 00 et 59';
                  }
                  return true;
                },
              },
            }}
            mask="_"
            format="##:##"
            className={disabledbutton ? 'disabled secondary' : 'secondary'}
            disabled={disabledbutton}
          />
        </div>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="end"
            label="Heure de fin"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                message: 'Veuillez renseigner l\'heure de fin au format ##:## ou une heure valide ',
              },
              validate: {
                isValidTime: (value) => {
                  const [hours, minutes] = value.split(':').map(Number);
                  if (hours < 0 || hours > 23) {
                    return 'Les heures doivent être entre 00 et 23';
                  }
                  if (minutes < 0 || minutes > 59) {
                    return 'Les minutes doivent être entre 00 et 59';
                  }
                  return true;
                },
              },
            }}
            mask="_"
            format="##:##"
            className={disabledbutton ? 'disabled secondary' : 'secondary'}
            disabled={disabledbutton}
          />
        </div>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="breakHours"
            label="Temps de pause"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                message: 'Veuillez renseigner le temps de pause ou une heure valide ',
              },
              validate: {
                isValidTime: (value) => {
                  const [hours, minutes] = value.split(':').map(Number);
                  if (hours < 0 || hours > 23) {
                    return 'Les heures doivent être entre 00 et 23';
                  }
                  if (minutes < 0 || minutes > 59) {
                    return 'Les minutes doivent être entre 00 et 59';
                  }
                  return true;
                },
              },
            }}
            mask="_"
            format="##:##"
            className={disabledbutton ? 'disabled secondary' : 'secondary'}
            disabled={disabledbutton}
          />
        </div>
        <h3>Production</h3>
        {
          day && ((day?.tags?.length === 0) || (day.tags === undefined)) ? (
            <div className={`${styles['container-field']} ${styles.target}`}>
              <InputNumber
                name="target"
                control={control}
                label="Livraisons réalisées"
                inline
                widthLabel="150px"
                className={disabledbutton ? 'disabled secondary' : 'secondary'}
                disabled={disabledbutton}
              />
            </div>
          )
            : (
              day && day.tags.map((d) => (
                <div className={`${styles['container-field']} ${styles.target}`} key={d._id}>
                  <InputNumber
                    name={d._id}
                    control={control}
                    label={d.tagName}
                    inline
                    widthLabel="150px"
                    className={disabledbutton ? 'disabled secondary' : 'secondary'}
                    disabled={disabledbutton}
                  />
                </div>
              ))
            )
        }
        <div className={`${styles['container-field']} ${styles.comments}`}>
          <Textarea
            name="comments"
            control={control}
            label="Ajouter un commentaire"
            widthLabel="150px"
            disabled={disabledbutton}
            className={disabledbutton ? 'disabled' : ''}
          />
        </div>
      </div>
      <div className={styles.containerSubmit}>
        {!!Object.keys(errors).length
          && [
            'start',
            'end',
            'breakHours',
            'night',
            'restHours',
            'kms',
            'licensePlate',
          ]
            .map((key) =>
              errors?.[key] ? (
                <div className={styles.errorMessage}>
                  <ErrorField
                    key={`error-${key}`}
                    message={errors?.[key].message}
                  />
                </div>
              ) : null)
            .filter((d) => d)[0]}
        <div className={styles.actions}>
          <button
            onClick={handleSubmit(onSubmit)}
            disabled={disabledbutton}
            className={disabledbutton ? 'disabled' : ''}
          >
            {isLoading ? (
              <span className="icon loader">
                <Loader className="secondary" size={20} />
              </span>
            ) : (
              <>
                {isDirty ? (
                  <span className="icon">
                    <BiSave size="24px" />
                  </span>
                ) : (
                  <span className="icon">
                    <BsCheck size="24px" />
                  </span>
                )}
              </>
            )}
            <span>Valider</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(DeliveryRoundDayForm);
